.leafmapcontainerparent {
  display: flex;
  justify-content: flex-start;
}

.leafmapcontainer {
  /* flex: 0 0 75%; */
  padding-top: 10px;
}

.leafmapcontainerinner {
  background-color: '#b4afaf';
  border-style: 'solid';
  border-width: 'thin';
  border-color: '#a5a5a5';
  border-radius: '1px';
  box-shadow: '2px 2px 8px #888888';
}

.leafmaplegend {
  /* flex: 0 0 50px; */
  padding-top: 10px;
  padding-left: 25px;
}