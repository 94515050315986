.invoices-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.invoices-header-left {
  flex: 1 1 auto;
}

.invoices-header-right {
  flex: 0 0 auto;
  margin-left: auto;
  cursor: pointer;
}

.invoices-header-h2 {
  padding-top: 10px;
}