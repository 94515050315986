.so-ReactTable-container {
  display: flex;
  /* height: 100%; */
  /* height: auto; */
  /* min-height: 100%; */
  /* overflow: hidden; */
  /* overflow: block; */
}

.so-ReactTable-left {
  flex: 0 1 auto;
  padding-bottom: 10px;
  /* background: green; */
}

.so-ReactTable-right {
  flex: 1 10 auto;
  /* background: blue; */
}