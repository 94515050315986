

.so-React-Container {
  /* box-sizing: border-box; */
  /* width: 100vw; */
  margin: 10px;
  padding-bottom:40px;
}


.so-overall-container {
  /* width: 100%; */
  width: 100%;
  /* padding: 20px; */
  /* margin: 10px; */
  /* border: 0px; */
  /* width: 100%; */
  /* box-sizing: border-box; */
}

.so-header-container {
  background-color: #f2f7ff;
  display: flex;
  flex-wrap: wrap;
  min-height: 62px;
  align-items: stretch;
  justify-content: flex-end;
  padding: 3px;
  /* width: 90%; */
  /* box-sizing: border-box; */
}

.so-header-container.not-logged {
  background-color: #f9f9ed;
}

.so-header-left {
  /* border: solid 1px red; */
  flex: 1 1 250px;
  max-width: 250px;
  min-width: 120px;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
/* 
.so-header-left-icon {
} */

.so-header-middle-container {
  /* border: solid 1px red; */
  padding-left: 10px;
  line-height: 1em;
  flex: 10 1 auto;
  display: flex;
  justify-content: center;
  /* align-self: flex-end; */
  align-items: flex-end; 
}

.so-header-middle-container > .so-header-middle {
  /* border: solid 1px green; */
  /* max-width: 500px; */
  padding: 0px;
  flex: 1 1 450px;
  max-width: 500px;
  min-width: 350px;
  cursor: pointer;
  /* background: blue; */
  text-align: center;
  color: red;
  font-weight: bold;
  /* align-self: flex-end; */
  /* justify-content: center; */
}

.so-header-name-lobutton-container {
  color: #949494;
  display: flex;
  justify-self: flex-end;
  padding-left: 10px;
}


.so-header-name-container {
  /* border: solid 1px red; */
  margin-left: auto;
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* .so-header-name {
  align-self: center;
} */
.so-header-logbutton-container {
  /* border: solid 1px red; */
  /* justify-content: right;
  align-self: center; */
  flex: 0 0 auto;
  padding-left: 10px;
  display: flex;
  align-items: center;
}



/* CONTENT BAR */

.content-columns-container {
  display: flex;
  /* width: inherit; */
  /* width: 900px; */
  box-sizing: border-box;

  /* border: 1px solid blue;
  background: greenyellow; */
}

.content-column-left {
  flex: 1 1 250px;
  max-width: 250px;
  /* border: 1px solid green; */
}

.content-column-left-sub {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.content-column-left-loggedusers {
  max-width: 235px;
  padding-top: 10px;
}

.content-column-right {
  /* margin-right: 285px; */
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 1 350px;
  /* border: 1px solid red; */
  /* width: 100%; */
  overflow-x: auto;
  overflow-y: hidden;
}

.so-menu-container {
  flex: 0 1 235px;
  max-width: 235px !important;
  margin-right: 5px;
  min-width: 100px;
  border-radius: .28571429rem;
  /* border:  solid 1px green; */
  border: solid 1px rgb(34, 36, 38, 0.15);
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.2);

}

.so-menu-item {
  border-bottom: solid 1px rgb(34, 36, 38, 0.15);
  /* max-width: 275px; */
  /* background-color: burlywood; */
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-end;
  /* align-items: center; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  word-wrap: break-word;
  font-size: 0.85em;
  position: relative;
}

.so-menu-item-active>.so-menu-item:after, .so-menu-item-active>.so-menu-item:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.so-menu-item-active>.so-menu-item:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #f2f2f2;
  border-width: 5px;
  margin-top: -5px;
}

.so-menu-item-active>.so-menu-item:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: rgb(34, 36, 38, 0.15);
  border-width: 7px;
  margin-top: -7px;
}

.so-menu-item-active>.so-menu-item {
  background-color: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

}

.so-menu-item:hover {
  background-color: #f2f2f2;
  transition: .2s ease;
}

.so-menu-container>a {
  color: black;
}

.so-menu-container>.so-menu-top, .so-menu-container>.so-menu-top:hover {
  background-color: #ffffe1;
  cursor: default;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: bold;
  min-height: 40px;
}

.so-menu-container>.so-menu-admin>.so-menu-item, .so-menu-container>.so-menu-admin>.so-menu-item:hover {
  background-color: #ffffe1;
  color: red;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  min-height: 40px;
}

.so-menu-container>.so-menu-admin>.so-menu-item>.so-menu-admin-icon, .so-menu-container>.so-menu-admin>.so-menu-item>.so-menu-admin-icon:hover {
  background-color: #ffffe1;
  border: none;
  margin-left: auto;
  transform: scale(.75, .75);
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
}

/* .so-menu-container > :last-child {
    border-bottom: none;
} */

.so-menu-icon {
  /* background-color: rgb(89, 197, 89); */
  flex: 0 1 auto;
}

.so-menu-heading {
  /* background-color: rgb(197, 89, 165); */
  display: flex;
  flex: 1 1 70px;
}

.so-menu-item-active>.so-menu-item>.so-menu-heading {
  font-weight: bold;
  color: #06477c;
  /* font-size: 34px; */
}

.so-menu-heading:hover {
  color: #ff8000;
}

.so-menu-counter {
  /* background-color: rgb(197, 186, 89); */
  text-align: right;
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: flex-start;
  padding-left: 2px;
}

.so-menu-addbutton {
  padding-left: 5px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  /* align-items: flex-start; */
}

.so-menu-addButton2 {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background-color: #2185d0;
  color: white;
  font-weight: 700;
  font-size: .71428571rem;
  /* text-align: center; */
  border-radius: .28571429rem;
  transition: .1s ease;
  padding-left: 2px;
  padding-right: 4px;
  /* align-self: start; */
  height: 22px;
  display:flex;
  align-items: center;
}

.so-menu-addButton2.active {
  background-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(34, 36, 38, .15);
  border-color: #2185d0;
  color: #2185d0;
}

.so-menu-addButton2:hover {
  background-color: red;
}

.so-menu-addButton2:active {
  background-color: green;
}

.so-menu-addButton2.active {
  background-color: white;
}

.so-menu-addButton2-plus {
  font-size: 20px;
  padding-right: 5px;
  margin-top: -5px;
  font-weight: 900;
}

.so-menu-container>.so-submenu-item, .so-menu-container>.so-submenu-item:hover {
  background-color: #f2ffff;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, .5);
  font-size: .80714286em;
  font-weight: 400;
  padding: .05em 2.8em;
}