/* .dashboard-overall-container {} */

.so-welcome-container {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  /* margin-bottom: 15px; */
}

.so-welcome-header {
  /* border: 1px solid green; */
  flex: 1 1 auto;
  align-self: center;
}

.so-welcome-icon {
  flex: 0 0 auto;
}

.so-welcome-selector {
  flex: 0 0 auto;
  margin-left: auto;
  /* border: 3px solid saddlebrown */
}