.container {
  position: absolute;
  bottom: 10px;
  left: 5px;
  z-index: 1000;
  background-color: rgb(114, 113, 172, .2);
  border: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 2px 2px;
  padding: 5px;
  font-size: 13px;
  line-height: normal;
  height: auto;
  width: auto;
}

.address2 {
  background-color: rgb(245, 245, 245);
  border: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 2px 2px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: normal;
  height: auto;
  width: auto;
}

.address-header {
  color: #ff8040;
  display: block;
}

.Jbutton {
  color: rgb(255, 255, 255);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  width: 75px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.clearButton {
  background-color: rgba(255, 0, 0, 1);
}

.clearButton:hover {
  background-color: rgb(184, 3, 3);
}

.clearButton:active {
  background-color: rgb(235, 154, 3);
}

.acceptButton {
  background-color: rgb(0, 170, 0);
}

.acceptButton:hover {
  background-color: rgb(3, 124, 23);
}

.acceptButton:active {
  background-color: rgb(2, 150, 142);
}

.disabledButton {
  background-color: rgb(107, 107, 107);
  color: rgb(255, 255, 255);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px 5px 5px 5px;
  cursor: not-allowed;
  width: 75px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.acceptButtonTooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.acceptButtonTooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.acceptButtonTooltip:hover .tooltiptext {
  visibility: visible;
}

.acceptButtonTooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}