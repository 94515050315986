.dashboard-header {
  margin-top: 20px;
  margin-bottom: 20px;
  /* width: 100%; */
  /* overflow-y: hidden; */
}

.dashboard-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -10px;
  /* width: 1000px; */
}

.dashboard-item1 {
  padding-right: 20px;
  margin-bottom: 10px;
  flex: 0 3 300px;
}

.dashboard-item2 {
  padding-right: 20px;
  margin-bottom: 10px;
  flex: 1 1 400px;
}

.dashboard-item2-0 {
  padding-right: 20px;
  margin-bottom: 10px;
  flex: 0 1 400px;
}