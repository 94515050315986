.so-charts-overall-container {
  /* border: 1px solid orange; */
  width: 100%;
  /* padding-right: 14px; */
  /* overflow-x: auto; */
}

.so-charts-chart-container {
  /* width: 99%; */
  /* width: 200px; */
  /* background: cyan; */
  /* border: 1px dotted black; */
  margin: 0;
  /* overflow-x: auto; */
}

.so-charts-charts-selector {
  /* background: green; */
  padding-right: 10px;
  width: auto;
  /* overflow-x: auto; */
}

.so-charts-namebar-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 35px;
  margin-bottom: 15px;
}

.so-charts-namebar-name {
  flex: 1 1 auto;
}

.so-charts-namebar-icons {
  flex: 0 0 200px;
  margin-left: auto;
}

.so-charts-menubar-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 920px;
  min-height: 2.85714286em;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.07142857rem;
  border-bottom: none;
  background: #1b1c1d;
  border-radius: 10px;
  transition: .4s ease;
}

.so-charts-menubar-item {
  --example-custom-prop: #00000;
}

.so-charts-menubar-item {
  flex: 1 0 auto;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 2px solid transparent;
  padding: .92857143em 1.42857143em;
  color: rgba(255, 255, 255, .9);
  cursor: pointer;
  background: 0 0;
  border-radius: 10px;
}

.so-charts-menubar-item:hover {
  background: rgba(255, 255, 255, .08);
}

.so-charts-menubar-item-active>.so-charts-menubar-item:after, .so-charts-menubar-item-active>.so-charts-menubar-item:before {
  /* visibility: hidden; */
  position: absolute;
  content: '';
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  /* background: 0 0; */
  background: white;
  margin: .5px 0 0;
  width: .57142857em;
  height: .57142857em;
  border: none;
  border-bottom: 1px solid #d4d4d5;
  border-right: 1px solid #d4d4d5;
  z-index: 2;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;
}

@media all and (max-width: 1230px) {
  .so-charts-menubar-container {
    max-width: 450px;
  }
  .so-charts-menubar-item {
    flex: 1 0 50%;
  }
}

@media all and (max-width: 625px) {
  .so-charts-menubar-container {
    max-width: 180px;
  }
  .so-charts-menubar-item {
    flex: 1 0 100%;
  }
}