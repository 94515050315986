/* GENERAL LEAFLET CSS (NECESSARY!!!!!) */
.leaflet-container {
  width: 100%;
  height: 100%;
}


.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* CLUSTER CSS */

.cluster-marker2 {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker2:hover {
  color: rgb(245, 39, 39);
  background: #062136;
}

.parent-div2 {
  position: absolute;
  z-index: 0;
}


.facility-marker2 {
  background: none;
  border: none;
  cursor: pointer;
}

.info-card2 {
  width: 240px;
  /* height: '100%'; */
  color: #fff;
  background: #2c99f1;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  padding: 10px;
  /* display: flex; */
  justify-content: left;
  align-items: left;
  cursor: pointer;
  z-index: 999;
  position: absolute;
  top: 100px;
  right: 10px;
}

/* On mouse-over, add a deeper shadow */
.info-card2:hover {
  background: #1978c8;
  box-shadow: 5px 8px 16px rgb(230, 238, 117);
}

.info-card-header2 {
  background: #032038;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  transition: 0.3s;
  border-radius: 5px 5px 0 0; /* 5px rounded corners */
  padding: 10px;
  /* display: flex; */
  justify-content: left;
  align-items: left;
  cursor: pointer
}

.info-card-details2 {
  background: #414146;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 0 0 5px 5px; /* 5px rounded corners */
  padding: 10px;
  /* display: flex; */
  justify-content: left;
  align-items: left;
  cursor: pointer
}

.info-card-clientmatter2:hover {
  font-weight: bold;
  color: #ff8000
}


.facility-card2 {
  width: 175px;
  /* height: 50px; */
  color: #fff;
  background: #2c99f1;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  padding: 10px;
  /* display: flex; */
  justify-content: left;
  align-items: left;
  white-space: pre-line;
  cursor: pointer
}

.zoom-card2 {
  width: 200px;
  color: #fff;
  background: #1978c8;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

/* GEOLOCATOR BAR */

.jake-search-container2 {
  /* background-color: rgba(236, 236, 236, 0.897); */
  padding: 5px;
  width: 300px;
  position: absolute;
	left: 5px;
  top: 5px;
  z-index: 1000;
}

.jake-control-input2 {
	/* position: absolute;
	left: 0;
	top: 0; */
	background-color: white !important;
	background-repeat: no-repeat !important;
	background-image: url("../charts/images/search.png") !important;
	background-size: 26px !important;
  background-position: right center !important;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.65) !important;
  border: honeydew !important;
  display: block !important;
  /* border-width: 5px; */
  border-radius: 5px !important;
	padding: 5px !important;
	text-indent: 6px !important;
	font-size: 13px !important;
	line-height: normal !important;
	height: auto !important;
  width: 27px !important;
	cursor: pointer !important;
  transition-property: width !important;
  transition-duration: .2s !important;
  transition-timing-function: ease !important;
}

.jake-control-input2:focus,
.jake-control-input2:active {
  width: 275px !important;
  border-radius: 2px !important;
  outline: none !important;
  
}

.jake-autocomplete-dropdown-container2:not(:empty) {
  background-color: rgb(114, 113, 172, .2);
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  padding: 5px;
	text-indent: 6px;
	font-size: 13px;
	line-height: normal;
	height: auto;
  width: 275px;
}

.jake-autocomplete-loading2 {
  background-color: rgb(203, 202, 248);
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  color: rgb(250, 250, 250);
}

.jake-suggestion-item-active2 {
  background-color: rgb(186, 241, 248);
  cursor: pointer;
  padding: 5px;
  border-top: 1px solid rgb(0, 0, 0);
  border-bottom: 1px solid rgb(0, 0, 0);
}

.jake-suggestion-item2 {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
  padding: 5px;
  border-top: 1px solid rgb(190, 190, 190);
  border-bottom: 1px solid rgb(190, 190, 190);
}
.jake-suggestion-item-active2:first-child {
  border-top: none;
}
.jake-suggestion-item2:first-child {
  border-top: none;
}
.jake-suggestion-item-active2:last-child {
  border-bottom: none;
}
.jake-suggestion-item2:last-child {
  border-bottom: none;
}











/* ADDRESS FOUND STUFF */

.container2 {
  position: absolute;
  bottom: 10px;
  left: 5px;
  z-index: 1000;
  background-color: rgb(114, 113, 172, .2);
  border: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 2px 2px;
  padding: 5px;
  font-size: 13px;
  line-height: normal;
  height: auto;
  width: auto;
}

.address2 {
  background-color: rgb(245, 245, 245);
  border: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 2px 2px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: normal;
  height: auto;
  width: auto;
}

.address-header2 {
  color: #ff8040;
  display: block;
}

.Jbutton2 {
  color: rgb(255, 255, 255);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  width: 75px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.clearButton2 {
  background-color: rgba(255, 0, 0, 1);
}

.clearButton2:hover {
  background-color: rgb(184, 3, 3);
}

.clearButton2:active {
  background-color: rgb(235, 154, 3);
}

.acceptButton2 {
  background-color: rgb(0, 170, 0);
}

.acceptButton2:hover {
  background-color: rgb(3, 124, 23);
}

.acceptButton2:active {
  background-color: rgb(2, 150, 142);
}

.disabledButton2 {
  background-color: rgb(107, 107, 107);
  color: rgb(255, 255, 255);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px 5px 5px 5px;
  cursor: not-allowed;
  width: 75px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.acceptButtonTooltip2 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.acceptButtonTooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.acceptButtonTooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.acceptButtonTooltip2 .tooltiptext2::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}




/* ZOOM CONTROLS */

.zoom-container2 {
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 1000;
  background-color: rgb(114, 113, 172, .2);
  border: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 2px 2px;
  padding: 5px;
  font-size: 13px;
  line-height: normal;
  height: auto;
  width: 160px;
  text-align: right;
}

.zoom-latlng2 {
  font-size: 10px;
  height: 10px;
}

.zoom-latlng-button2 {
  width: 50%;
  display: inline-block;
  text-align: left;
  color: rgb(0, 44, 139);
}

.zoom-control2 {
  color: rgb(255, 255, 255);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 2px 2px 2px 2px;
  cursor: pointer;
  width: auto;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  display: inline-block;
}

.zoomButton2 {
  background-color: rgb(36, 4, 153);
}
.zoomButton2:hover {
  background-color: rgb(184, 3, 3);
}
.zoomButton2:active {
  background-color: rgb(235, 154, 3);

}

.zoom-control-showClusters {
  clear: right;
}